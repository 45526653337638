<template>
    <div class="cat_items_root">
        <Carousel :perPageCustom="[
            [200, 1],
            [768, 1],
        ]" paginationActiveColor="#E87613" paginationColor="#D9D9D9" :paginationEnabled="false" v-bind:autoplay="true"
            v-bind:loop="true" v-bind:autoplayTimeout="3000" v-bind:autoplayHoverPause="false"
            class="selected_category_banner_image_container" v-if="selectedCategory && variants.has(selectedCategory.name)">
            <slide v-for="(obj, indx) in selectedCategory.banner" :key="indx" class="image_x">
                <Banner :val="obj" :style="{ 'background-image': `url(${obj.imageUrl})` }"
                    class="selected_category_banner_image" :showBtn="false" />
            </slide>
        </Carousel>
        <div class="filter_relevance">
            <div @click="toggleSidebar" style="cursor: pointer;">
                <img src="../../../assets/yicimages/store/filter.png" style="color: #000;" />
                Filters
                <i class="fa-solid fa-chevron-right"></i>
            </div>
            
            <div>
                <b-dropdown text="Relevance" variant="no-color" id="relevence_text">
                    <!-- <i class="fa-solid fa-chevron-right"></i> -->
                    <b-dropdown-item @click="sortAccToPrice('ascending')">
                        Price: low to high
                    </b-dropdown-item>
                    <b-dropdown-item @click="sortAccToPrice('descending')">
                        Price: high to low
                    </b-dropdown-item>
                </b-dropdown>
            </div>
        </div>
        <div class="cat_items__">
            <div title="Filters" class="filter_sidebar" v-if="showSidebar">
                <div style="display: flex; flex-direction: column; margin: 1rem;" class="sidebar__">
                    <div style="display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center;">
                        <div class="sidebar_headings">OUT OF STOCK</div>
                        <div class="button-container">
                            <b-button variant="no-color" v-bind:class="[showBtnActive ? 'active' : 'show_hide_btn']"
                                @click="handleFilter('show')">Show</b-button>
                            <b-button variant="no-color" v-bind:class="[hideBtnActive ? 'active' : 'show_hide_btn']"
                                @click="handleFilter('hide')">Hide</b-button>
                        </div>
                    </div>
                    <div>
                        <div class="sidebar_headings">CATEGORIES</div>
                        <div v-for="item in subCategories.get(selectedCategory.name)"
                            style="display: flex; flex-direction: row; font-size: 1.1rem; font-weight: 200;">
                            <b-form-checkbox-group>
                                <b-form-checkbox size="lg" v-model="item.showSelectedCategory"
                                    @change="changeCategorySelection(item)"></b-form-checkbox>
                            </b-form-checkbox-group>
                            <div style="font-size: 1rem; font-weight: 500;">{{ item.name }}</div>
                        </div>
                    </div>
                    <div style="width: 100%;">
                        <div class="sidebar_headings">PRICE RANGE</div>
                        <div class="range_filter_cont">
                            <b-form-input v-if="priceMinValue<=0" placeholder="0"></b-form-input>
                            <b-form-input v-else v-model="priceMinValue"></b-form-input>
                            <b-form-input v-if="priceMaxValue<=0" placeholder="0" @input="filterAccordingToPrice"></b-form-input>
                            <b-form-input v-else v-model="priceMaxValue" @input="filterAccordingToPrice"></b-form-input>
                        </div>
                        <input type="range" class="slider_filter" id="range-slider" min="0" max="10000"
                            :value="[priceMaxValue != '' ? priceMaxValue : 0]"/>
                    </div>
                    <div style="width: 100%;">
                        <b-button variant="none" class="active" @click="resetFilter">RESET FILTER</b-button>
                    </div>
                </div>
            </div>
            <div class="cat_items">
                <div v-if="selectedCategory && variants.has(selectedCategory.name)" class="all_products">
                    <div v-for="item in productIds">
                        <CategoryItem :val="variants.get(selectedCategory.name)[item]" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import CategoryItem from './CategoryItem.vue';
import { Carousel, Slide } from "vue-carousel";
import Banner from "./Banner.vue";
import { BSidebar, BDropdown, VBToggle, BButton, BFormGroup, BFormCheckbox, BFormInput, BDropdownItem, BFormCheckboxGroup } from 'bootstrap-vue';
import { IoTThingsGraph } from 'aws-sdk';

export default {
    components: {
        CategoryItem,
        Carousel,
        Slide,
        Banner,
        BSidebar,
        BDropdown,
        BButton,
        BFormGroup,
        BFormCheckbox,
        BFormInput,
        BDropdownItem,
        BFormCheckboxGroup
    },
    data() {
        return {
            showBtnActive: true,
            hideBtnActive: false,
            priceMinValue: 0,
            priceMaxValue: 0,
            weightMinValue: 0,
            weightMaxValue: 0,
            sortByPrice: false,
            filterAccToQuantity: false,
            selectedCategories: [],
            showSidebar: false,
        }
    },
    directives: {
        'b-toggle': VBToggle,
    },
    computed: {
        ...mapState({
            products: state => {
                return state.storeProducts.products
            },
            variants: state => {
                return state.storeProducts.tempVariant
            },
            selectedCategory: state => {
                return state.storeProducts.selectedCategory
            },
            selectedSubCategory: state => {
                return state.storeProducts.selectedSubCategory
            },
            productCategories: state => {
                return state.storeProducts.productCategories
            },
            subCategories: state => {
                return state.storeProducts.subCategories
            },
            productIds: state => {
                return state.storeProducts.productIds
            }
        })
    },
    methods: {
        ...mapActions({
            setSelectedCategoryProducts: "storeProducts/setSelectedCategoryProducts",
            fetchProducts: "storeProducts/fetchProducts",
            getVariants: "storeProducts/getTempVariant",
        }),
        ...mapMutations({}),
        async handleFilter(val) {
            if (val == 'show') {
                this.showBtnActive = !this.showBtnActive
                this.hideBtnActive = false
                this.filterAccToQuantity = false
                console.log("show called");
                if (JSON.parse(localStorage.getItem("subCategory"))) {
                    await this.getProductVariants()
                }
                else {
                    await this.getProductVariants()
                }
            }
            else if (val == 'hide') {
                this.hideBtnActive = !this.hideBtnActive
                this.showBtnActive = false
                this.filterAccToQuantity = true
                console.log("hide called");
                if (JSON.parse(localStorage.getItem("subCategory"))) {
                    await this.getProductVariants()
                }
                else {
                    await this.getProductVariants()
                }
            }
        },
        async changeCategorySelection(item) {
            let isPresent = false
            if (this.selectedCategories.indexOf(item) !== -1) {
                isPresent = true
            }
            if (isPresent === false && item.showSelectedCategory) {
                item.showSelectedCategory = true
                this.selectedCategories.push(item)
            }
            else {
                const index = this.selectedCategories.indexOf(item)
                item.showSelectedCategory = false
                this.selectedCategories.splice(index, 1)
            }
            try {
                if (JSON.parse(localStorage.getItem("category"))) {
                    await this.getProductVariants()
                }
            }
            catch (err) {
                console.log("category name not defined", err);
            }
        },
        async filterAccordingToPrice() {
            if (this.priceMinValue < this.priceMaxValue) {
                if (JSON.parse(localStorage.getItem("subCategory"))) {
                    await this.getProductVariants()
                }
                else {
                    await this.getProductVariants()
                }
            }
        },
        async filterAccordingToWeight() {
            if (this.weightMinValue < this.weightMaxValue) {
                if (JSON.parse(localStorage.getItem("subCategory"))) {
                    await this.getProductVariants()
                }
                else {
                    await this.getProductVariants()
                }
            }
        },
        async sortAccToPrice(val) {
            if (val == 'ascending') {
                this.sortByPrice = false
            }
            else {
                this.sortByPrice = true
            }
            if (JSON.parse(localStorage.getItem("subCategory"))) {
                await this.getProductVariants()
            }
            else {
                await this.getProductVariants()
            }
        },
        async resetFilter(){
            this.priceMinValue = 0
            this.priceMaxValue = 0
            this.sortByPrice = false
            this.filterAccToQuantity = false
            this.selectedCategories = []
            this.subCategories.get(this.selectedCategory.name).map(cat => {
                cat.showSelectedCategory = false
            })
            await this.getProductVariants()
        },
        async getProductVariants(){
            await this.getVariants({ selectedCategory: this.selectedCategory, selectedSubCategory: JSON.parse(localStorage.getItem("subCategory")), filterAccToQuantity: this.filterAccToQuantity, minPrice: this.priceMinValue ? this.priceMinValue : 0, maxPrice: this.priceMaxValue ? this.priceMaxValue : 0, minWeight: this.weightMinValue ? this.weightMinValue : 0, maxWeight: this.weightMaxValue ? this.weightMaxValue : 0, sortByPrice: this.sortByPrice, selectedSubCategories: this.selectedCategories })
        },
        toggleSidebar(){
            this.showSidebar = !this.showSidebar
        },
    },
    async mounted() {
        try {
            if (JSON.parse(localStorage.getItem("category"))) {
                await this.setSelectedCategoryProducts({ selectedCategory: JSON.parse(localStorage.getItem("category")) })
                if (JSON.parse(localStorage.getItem("subCategory"))) {
                    await this.getVariants({ selectedCategory: this.selectedCategory, selectedSubCategory: JSON.parse(localStorage.getItem("subCategory")), sortByPrice: this.sortByPrice })
                }
                else {
                    await this.getVariants({ selectedCategory: this.selectedCategory, sortByPrice: this.sortByPrice })
                }
            }
        }
        catch (err) {
            console.log("category name not defined", err);
        }
    },
    watch: {
        async selectedSubCategory(nVal, oVal) {
            if (nVal != oVal && nVal != null) {
                try {
                    if (JSON.parse(localStorage.getItem("category"))) {
                        await this.setSelectedCategoryProducts({ selectedCategory: JSON.parse(localStorage.getItem("category")) })
                        if (JSON.parse(localStorage.getItem("subCategory"))) {
                            await this.getVariants({ selectedCategory: this.selectedCategory, selectedSubCategory: JSON.parse(localStorage.getItem("subCategory")) })
                        }
                        else {
                            await this.getVariants({ selectedCategory: this.selectedCategory })
                        }
                    }
                }
                catch (err) {
                    console.log("sub category name not defined", err);
                }
            }
        },
    }
}
</script>

<style scoped>
.all_products {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 3rem;
    justify-content: center;
}

.cat_items {
    margin: 3rem;
}

.cat_items_root {
    margin: 0;
}

.selected_category_banner_image_container {
    width: 100%;
    height: 40rem;
    margin-bottom: 2rem;
    margin-left: 0;
}

.image_x {
    height: 100%;
    width: 40rem;
    background-image: url('../../../assets/yicimages/store/jewellery.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
    object-fit: cover;
}

.selected_category_banner_image {
    width: 100%;
    height: 40rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
    object-fit: cover;
}

.filter_relevance {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: 0 4rem 0 4rem;
    font-size: 1.5rem;
    color: #000;
    font-weight: 600;
}

.active {
    background: #000080;
    color: white;
}

.show_hide_btn {
    color: #000;
    background: white;
}

.button-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.button-container>* {
    margin: 0.5rem;
}

.sidebar_headings {
    color: #000;
    font-size: 1.1rem;
    margin-right: 0.5rem;
}

.sidebar__>* {
    margin-bottom: 2rem;
}

.range_filter_cont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.range_filter_cont>* {
    margin: 0.5rem;
}

.slider_filter {
    background: black !important;
    color: black !important;
    width: 100%;
}
.filter_sidebar{
    width: 25%;
    transition: width 0.3s ease;
    background: #FFF;
    margin: 3rem 1rem 0 3rem;
    border-radius: 0.5rem;
    box-shadow: 4px 0px 10px 0px rgba(0, 0, 0, 0.05);
    height: 100%;
}
.cat_items__{
    display: flex;
}
@media screen and (max-width: 800px) {
    .filter_relevance {
        padding: 0 1rem 0 2rem;
        font-size: 1.2rem;
        color: #000;
    }
    .all_products {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }
    .filter_sidebar{
        z-index: 2;
        width: 100%;
        transition: width 0.3s ease;
        background: #FFF;
        margin: 3rem 1rem 0 3rem;
        border-radius: 0.5rem;
        box-shadow: 4px 0px 10px 0px rgba(0, 0, 0, 0.05);
        height: 100%;
        position: absolute;
    }
    .cat_items_root{
        position: relative;
        z-index: 1;
    }
    .cat_items{
        margin: 1rem;
    }
}
</style>

<style>
#relevence_text>button {
    color: #000;
    font-size: 1.5rem;
    font-weight: 600;
    background: white;
    cursor: pointer;
}

@media screen and (max-width: 800px) {
    #relevence_text>button {
        font-size: 1.2rem;
    }
}
</style>