<template>
    <div class="category_item_root" v-if="val && val.length > 0">
        <div class="super_root_item" @click="onClick(val[0].products[0].id)">
            <div class="store_product_image_cont">
                <img :src="val[0].products[0].productImages[0]" alt="No image found" class="store_product_image" />
            </div>
            <div style="margin-left: 0.5rem; margin-top: 0.3rem;" class="bold_ break_word name_txt">{{
                val[0].products[0].name }}
            </div>
            <div style="font-size: 1rem; margin-left: 0.5rem;" class="break_word">{{ val[0].products[0].description }}</div>
            <div v-if="val && val[0]" style="margin-left: 0.5rem;" class="title_text">{{ val[0].title }}: <span
                    class="bold_">{{
                        val[0].name }}</span></div>
            <div v-if="val && val[0]" style="margin-left: 0.5rem;" class="blue_color bold_  price_txt">₹
                {{ val[0].priceOffer ? val[0].priceOffer : val[0].priceOriginal }}</div>
        </div>
        <div style="min-width: 4rem; height: 3.8rem;" @click="addOrRemoveFromCart">
            <b-button v-if="val[0].inventoryQuantity <= 0" variant="none" class="add_to_cart_btn" :disabled="true">Out Of
                Stock</b-button>
            <b-button v-else variant="none" class="add_to_cart_btn">Add to cart</b-button>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { BButton } from "bootstrap-vue";

export default {
    components: {
        BButton
    },
    data() {
        return {
            vari: [],
            isItemInCart: false
        }
    },
    props: ['val'],
    computed: {
        ...mapState({
            products: state => {
                return state.storeProducts.products
            },
            tempVariant: state => {
                return state.storeProducts.tempVariant
            },
            selectedCategory: state => {
                return state.storeProducts.selectedCategory
            },
            currUser(state) {
                return state.login.currUser;
            },
        })
    },
    methods: {
        ...mapActions({
            addItemToCart: "storeShoppingCartItems/addItemToCart",
            setShoppingCartItems: "storeShoppingCartItems/setShoppingCartItems",
            getItemInCart: "storeShoppingCartItems/getItemInCart",
            deleteFromCart: "storeShoppingCartItems/deleteFromCart"
        }),
        ...mapMutations({}),
        onClick(_id) {
            console.log("fucntion called")
            this.$router.push(`/store/product/${_id}`);
        },
        async addOrRemoveFromCart() {
            const productId = this.val[0].products[0].id;
            const quan = 1;
            const variantId = this.val[0].id;
            const anonymousUserId = localStorage.getItem("anonymousUserId");
            const userId = this.currUser ? this.currUser.id : null;
            await this.addItemToCart({ quan, productId, variantId, anonymousUserId, userId });
            await this.setShoppingCartItems({ userId });
            this.isItemInCart = true
            this.$swal({
                icon: 'success',
                title: 'Item added to cart successfully',
            }).then(function () {
            });
            // }
        },
        async findItemInCart() {
            const anonymousUserId = localStorage.getItem("anonymousUserId");
            const userId = this.currUser ? this.currUser.id : null;
            const res = await this.getItemInCart({ productId: this.val && this.val[0].products[0].id, variantId: this.val && this.val[0].id, anonymousUserId: anonymousUserId, userId: userId })
            if (res.data.length > 0)
                this.isItemInCart = true;
            return res
        }
    },
    async mounted() {
        // await this.findItemInCart()
    },
    watch: {
        async tempVariant(nv, ol) {
            if (nv != ol) {

            }
        },
    }
}
</script>

<style>
.category_item_root {
    display: flex;
    flex-direction: column;
    min-height: 30rem;
    width: 22rem;
}

.super_root_item {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    font-weight: normal;
    cursor: pointer;
    border-radius: 0.5rem;
    gap: 0.2rem;
    overflow-y: hidden;
}

.category_item_root:hover {
    border-radius: 0.5rem;
    background: #FFF;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.10);
    transition: 0.3s;
}

.store_product_image_cont {
    width: 100%;
    height: 16rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem 0.5rem 0 0;
}

.store_product_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.5rem 0.5rem 0 0;
}

.blue_color {
    color: #000080;
}

.add_to_cart_btn {
    margin-top: 2.2rem;
    color: #FF6F00;
    padding: 0.7rem;
    background: rgba(255, 111, 0, 0.10);
    width: 100%;
    height: 100%;
    font-size: 1.3rem;
    font-weight: 600;
    border-radius: 0 0 0.5rem 0.5rem;
}

.add_to_cart_btn:hover {
    color: white;
    background: #FF6F00;
}

.bold_ {
    font-weight: 600;
}

.break_word {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.name_txt {
    font-size: 1.5rem;
}

.price_txt {
    font-size: 1.5rem;
}

.title_text {
    font-size: 1rem;
}

@media screen and (max-width: 800px) {
    .category_item_root {
        min-height: 24rem;
        width: 10.5rem;
    }

    .name_txt {
        font-size: 1.2rem;
    }

    .price_txt {
        font-size: 1.2rem;
    }

    .title_text {
        font-size: 0.9rem;
    }

    .store_product_image_cont {
        height: 10rem;
    }
    .store_product_image {
        object-fit: cover;
    }
}</style>